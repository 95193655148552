import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import { Medailon, PaddingWrapper } from "@igloonet-web/shared-ui"
import Logo from "../../../images/reference/moravske-drevostavby/logo.png"
import Screen from "../../../images/reference/moravske-drevostavby/md.png"
import Sideimg from "../../../images/reference/moravske-drevostavby/screen-1.png"
import Ales from "../../../images/reference/moravske-drevostavby/reaction-devel.png"
import ReferenceHeader from "../../../components/reference-detail/reference-header"
import ReferenceImageText from "../../../components/reference-detail/reference-image-text"

class Drevostavby extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>
            Reference na nový web pro MORAVSKÉ DŘEVOSTAVBY | igloonet
          </title>
          <meta
            name="description"
            content="Marketing, vývoj a hosting pod střechou jedné firmy. Programujeme moderní eshopy, poskytujeme prvotřídní hostingové služby a tvoříme marketingové strategie na míru."
          />
        </Helmet>

        <ReferenceHeader
          companyName="moravské dřevostavby"
          heading="Reference na nový web pro MORAVSKÉ DŘEVOSTAVBY"
          date="2014"
        />

        <ReferenceImageText
          right
          img={Screen}
          eager
          alt=""
          logo={Logo}
          logoAlt="logo společnosti Moravské Dřevostavby"
          link="//moravske-drevostavby.cz/"
        >
          Společnost MORAVSKÉ DŘEVOSTAVBY se zabývá výstavbou energeticky
          úsporných a nízkoenergetických typových dřevostaveb za použití Systému
          DNK. V jejich práci si berou za vzor moravskou poctivost a
          pohostinnost.
        </ReferenceImageText>

        <ReferenceImageText>
          <h2>Zadání</h2>
          <p>
            Vytvořit webové stránky pro nově založenou společnost zabývající se
            výstavbou dřevostaveb.
          </p>

          <div className="py-5">
            <h2>Rozsah projektu</h2>
            <ul>
              <li>krátký průzkum trhu a návrh wireframů</li>
              <li>vytvoření vlastní grafiky</li>
              <li>zpracování do HTML kódu (neresponzivní)</li>
              <li>nasazení na redakní systém Drupal</li>
            </ul>
          </div>
        </ReferenceImageText>

        <div className="pb-5">
          <ReferenceImageText halfImgSize img={Sideimg} alt="">
            <h2>Vyjádření zadavatele</h2>
            <p>
              <em>
                Jsem asi poněkud skeptický k doporučením na různé služby přátel
                svých obchodních partnerů, ale musím přiznat, že pan Kudlík
                podpořil svou zajímavou nabídku seriózním a odborným přístupem k
                mému zadání. Slovo dalo slovo a pod rukama jeho týmu se začal
                rodit náš web. Zprvu to znamenalo jen dodávání dalších a dalších
                textů a obrázků, ale pak se se objevila první grafika a web nám
                doslova vyrostl pod rukama.
              </em>
            </p>
          </ReferenceImageText>
        </div>

        <PaddingWrapper>
          <Medailon
            personName="Ing. Aleš Tajbr, Ph.D."
            position="ředitel"
            img={Ales}
          >
            <p>
              Spolupráci s panem Kudlíkem a jeho společností igloonet s.r.o.
              hodnotím kladně, protože dodrželi zadání projektu, rozpočet i
              časový harmonogram.
            </p>
          </Medailon>
        </PaddingWrapper>

        <ContactForm contact="sobol" />
      </Layout>
    )
  }
}

export default Drevostavby
